import React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import Navigation from "../components/navigation";
import CtaBanner from "../components/cta-banner";

const BlogPostTemplate = ({ data: { previous, next, post } }) => {
	return (
		<Layout>
			<Navigation />
			<Container className="mb-7">
				<GatsbySeo
					title={`${post.title} | ARC Blog`}
					description={post.blogSeo.metaDescription}
					language="en"
					openGraph={{
						title: `${post.title}`,
						description: `${post.blogSeo.metaDescription}`,
						url: ``,
						type: "article",
						images: [
							{
								url: `${post.blogFields.featuredImage.localFile.publicURL}`,
							},
						],
					}}
				/>
				<Row className="justify-content-center mt-5">
					<Col xs={12}>
						<div className="shadow py-4 px-md-5 px-3">
							<header>
								<h1 className="mb-4 text-lg-center">{parse(post.title)}</h1>
							</header>

							{!!post.content && (
								<section className="blog-post">{parse(post.content)}</section>
							)}
						</div>
					</Col>
				</Row>
			</Container>
			<CtaBanner
				headline={
					<>
						I'd love to hear more about your
						<br className="d-none d-md-block" /> needs, and how I can help.
					</>
				}
				bgColor="dark-background"
				btnTxt="Contact"
				btnLink="/contact"
			/>
		</Layout>
	);
};

export default BlogPostTemplate;

export const pageQuery = graphql`
	query BlogPostById(
		$id: String!
		$previousPostId: String
		$nextPostId: String
	) {
		post: wpPost(id: { eq: $id }) {
			id
			excerpt
			content
			title
			date(formatString: "MMMM DD, YYYY")
			blogSeo {
				metaDescription
			}
			blogFields {
				featuredImage {
					altText
					localFile {
						publicURL
						childImageSharp {
							gatsbyImageData(
								quality: 100
								placeholder: BLURRED
								layout: FULL_WIDTH
							)
						}
					}
				}
			}
		}
		previous: wpPost(id: { eq: $previousPostId }) {
			uri
			title
		}
		next: wpPost(id: { eq: $nextPostId }) {
			uri
			title
		}
	}
`;
